import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { useAppDispatch } from '@core/hooks/appHooks';
import { getInviteCode } from '@core/services/networkService/_inviteCodes';
import { FSCustomer } from '@core/services/networkService/customer/customer';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { setFirestoreUser } from '@core/store/userSlice/userSlice';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import DemmiCard from '@demmi-ui/CardOld/Card';
import DemmiInput from '@demmi-ui/Input';
import { useAuth } from '@providers/authProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

interface Props {
  onClickBack: () => void;
}

const CardUserNotFound: React.FC<Props> = ({ onClickBack }) => {
  const CSSBlock = 'card-user-not-found';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const [authProviderInfo, setAuthProviderInfo] = useState<DemmiFS.AuthProviderHelper.FBAuthProviderInfo>();

  const [email, setEmail] = useState<string>('');
  const [inviteCode, setInviteCode] = useState<string>('');
  const [inviteCodeError, setInviteCodeError] = useState<string>();
  const [shakeCard, setShakeCard] = useState(false);
  const [isCheckingCode, setIsCheckingCode] = useState(false);

  useEffect(() => {
    Logger({ messages: ['useEffect', 'user change'], objs: { user, authProviderInfo } }, CardUserNotFound);
    if (user && user.providerData.length > 0) {
      setEmail(user.email ?? '');
      const provider = user.providerData[0];
      setAuthProviderInfo({
        email: provider.email ?? user.email ?? undefined,
        uid: user.uid,
        providerID: provider.providerId,
      });
    }
  }, [user]);

  const onSubmitCode = async () => {
    const code = inviteCode.replaceAll('-', '');
    if (code.length < 6 || !user) return;
    setIsCheckingCode(true);

    try {
      const inviteCode = await getInviteCode(code);
      const provider = authProviderInfo
        ? DemmiFS.AuthProviderHelper.getFBAuthProviderByID(authProviderInfo.providerID)
        : undefined;
      Logger({ objs: { inviteCode, authProviderInfo, provider } }, onSubmitCode);

      // Check if invite code is valid and if OAuth provider exists
      if (inviteCode && authProviderInfo && provider) {
        // Check if invite code is valid for the email
        if (inviteCode.email === email && authProviderInfo && provider) {
          await FSCustomer.doc.createNewDoc(user.uid, provider, authProviderInfo, email, code);
          const customer = await FSCustomer.doc.getByProvider(user.uid, provider);
          dispatch(setFirestoreUser(customer));
          navigate(routingPaths[RoutingViews.USER_SETUP], { replace: true });
        } else {
          // Email doesn't match code
          setInviteCodeError(`The code "${code}" is not valid\nfor your email address.`);
        }
      } else {
        setShakeCard(true);
        setInviteCodeError(`${code} is not a valid invite code`);
        setTimeout(() => setShakeCard(false), 1000);
      }
    } catch (err) {
      Logger({ objs: { err }, type: DemmiLogType.error }, onSubmitCode);
      setShakeCard(true);
      setInviteCodeError(`Something went wrong, please try again.`);
    }
    setIsCheckingCode(false);
  };

  const authView = () =>
    authProviderInfo && DemmiFS.AuthProviderHelper.isAuthApple(authProviderInfo)
      ? DemmiFS.AuthProviderHelper.isAuthAppleObscured(authProviderInfo)
        ? authProviderApplePrivate()
        : authProviderApple()
      : authProviderGoogle();

  const authProviderGoogle = () => (
    <>
      <div className={`${CSSBlock}__provider-name`}>It looks like you&apos;re signing in with your Google Account</div>
      <div className={`${CSSBlock}__provider-email`}>{authProviderInfo?.email}</div>
    </>
  );
  const authProviderApple = () => (
    <>
      <div className={`${CSSBlock}__provider-name`}>It looks like you&apos;re signing in with your Apple ID</div>
      <div className={`${CSSBlock}__provider-email`}>{authProviderInfo?.email}</div>
    </>
  );
  const authProviderApplePrivate = () => (
    <>
      <div className={`${CSSBlock}__provider-name`}>It seems like your Apple ID is obscured, but that&apos;s fine.</div>
      <div className={`${CSSBlock}__provider-email`}>{authProviderInfo?.email}</div>
      <div className={`${CSSBlock}__provider-name`}>
        Just make sure the email in the input below matches the email account your invite code was sent to.
      </div>
    </>
  );

  return (
    <div className={`${CSSBlock}__wrapper`}>
      <DemmiCard className={`${CSSBlock} ${CSSBlock}__card-1`}>
        <DemmiIconButton faIcon="fa-caret-left" onClick={onClickBack} />
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__title`}>Uh oh!</div>
          <div className={`${CSSBlock}__description`}>
            It looks like you&apos;re not currently a member of the Demmi community.
          </div>
          <div className={`${CSSBlock}__description`}>
            Unfortunately Demmi is currently in an invite-only beta release phase of its life, but don&apos;t worry,
            we&apos;re working hard to roll out to more users.
          </div>
        </div>
      </DemmiCard>

      <DemmiCard className={`${CSSBlock} ${CSSBlock}__card-2 ${shakeCard ? `${CSSBlock}--shake` : ``}`}>
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__title`}>
            I&apos;ve got a code,
            <br />
            let me in!
          </div>
          <div className={`${CSSBlock}__description`}>
            Perfect! Check your email, enter your invite code and we&apos;ll get you set up.
          </div>
          <div className={`${CSSBlock}__description ${CSSBlock}__description--provider`}>{authView()}</div>
          <div className={`${CSSBlock}__input-area ${isCheckingCode ? `${CSSBlock}__input-area--checking-code` : ``}`}>
            <div className={`${CSSBlock}__email`}>
              <DemmiInput
                disabled={isCheckingCode}
                value={email ?? ''}
                type="text"
                onChangeValue={v => setEmail(v)}
                placeholder="Email Address"
              />
              <ElementLoadingIndicator isLoading={isCheckingCode} />
            </div>
            <div className={`${CSSBlock}__invite-code`}>
              <DemmiInput
                disabled={isCheckingCode}
                value={inviteCode}
                type="text"
                onChangeValue={v => {
                  const val = v.replaceAll('-', '').substring(0, 6).toUpperCase();
                  setInviteCode(val.length > 3 ? `${val.substring(0, 3)}-${val.substring(3)}` : val);
                }}
                placeholder="XXX-XXX"
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') onSubmitCode();
                }}
              />
              <DemmiIconButton
                disabled={isCheckingCode || inviteCode.replaceAll('-', '').length < 6}
                faIcon="fa-caret-right"
                onClick={onSubmitCode}
              />
              <ElementLoadingIndicator isLoading={isCheckingCode} />
            </div>
            {inviteCodeError && <div className={`${CSSBlock}__invite-code-error`}>{inviteCodeError}</div>}
          </div>
        </div>
      </DemmiCard>
    </div>
  );
};

export default CardUserNotFound;
