import { LSKeys, LocalStorage } from '@core/localStorage';
import { ThemeMode, ThemeModeConstants } from '@helpers/theme.helper';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { DemmiFS } from '@subhanhabib/demmilib';

interface AppState {
  themeMode: ThemeMode;
  firestoreDemmi?: DemmiFS.DemmiConstants;
  activeRequests: string[];
  globalContentMounted: boolean;
}

const initialState: AppState = {
  themeMode: LocalStorage.get(LSKeys.THEME_MODE) as ThemeMode,
  firestoreDemmi: undefined,
  activeRequests: [],
  globalContentMounted: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  extraReducers: builder => {
    builder.addCase('LOGOUT', state => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setThemeMode: (state, action: PayloadAction<ThemeModeConstants>) => {
      state.themeMode = action.payload;
    },

    setFirestoreDemmi: (state, { payload }: PayloadAction<DemmiFS.DemmiConstants | undefined>) => {
      state.firestoreDemmi = payload;
    },

    pushNetworkRequestUUID: (state, { payload: uuid }: PayloadAction<string>) => {
      state.activeRequests = [...state.activeRequests, uuid];
    },

    popNetworkRequestUUID: (state, { payload: uuid }: PayloadAction<string>) => {
      state.activeRequests = state.activeRequests.filter(request => request !== uuid);
    },

    setGlobalContentMounted: (state, { payload }: PayloadAction<boolean>) => {
      if (state.globalContentMounted !== payload) state.globalContentMounted = payload;
    },
  },
});

export const {
  setThemeMode,
  setFirestoreDemmi,
  pushNetworkRequestUUID,
  popNetworkRequestUUID,
  setGlobalContentMounted,
} = appSlice.actions;

export const selectThemeMode = (state: RootState) => state.app.themeMode;
export const selectFirestoreDemmi = (state: RootState) => state.app.firestoreDemmi;
export const selectIsLoading = (state: RootState) => state.app.activeRequests.length > 0;
export const selectActiveRequests = (state: RootState) => state.app.activeRequests;
export const selectGlobalContentMounted = (state: RootState) => state.app.globalContentMounted;

export default appSlice.reducer;
