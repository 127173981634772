import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

import OrderItemTabProductItem from './_tab-product-item';

interface Props {
  CSSBlock: string;
  orderVendor: DemmiFS.FSOrderVendor;
  vendorDocID: string;
}

const OrderItemTabProducts: React.FC<Props> = ({ CSSBlock, orderVendor, vendorDocID }) => {
  return (
    <div className={`${CSSBlock}__products-list`}>
      {orderVendor.products.map((product, i) => (
        <OrderItemTabProductItem product={product} vendorDocID={vendorDocID} key={i} />
      ))}
    </div>
  );
};

export default OrderItemTabProducts;
