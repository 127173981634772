import React, { useEffect, useState } from 'react';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';
import { NetworkService } from '@core/services/networkService/networkService';
import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { formatPhoneNumber } from 'react-phone-number-input';
import OrderItemTabVendorDetailsHours from './_hours';

interface Props {
  CSSBlock: string;
  vendorDocID: string;
}

const getVendorAddress = (vendor: DemmiFS.Vendor) => {
  const addressDefinitions = DemmiFS.DemmiAddressHelper.getFieldDefinitions(vendor.address.countryCode);
  if (!addressDefinitions || !vendor.address.address) return '';

  let address = '';
  Object.entries(addressDefinitions).forEach(([key, _]) => {
    if (vendor.address.address![key as keyof DemmiFS.DemmiAddressFields])
      address += (address.length > 0 ? ', ' : '') + vendor.address.address![key as keyof DemmiFS.DemmiAddressFields];
  });
  return address;
};

const OrderItemTabVendorDetails: React.FC<Props> = ({ CSSBlock, vendorDocID }) => {
  const [vendor, setVendor] = useState<DemmiFS.Vendor>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchVendor();
  }, [vendorDocID]);

  const fetchVendor = async () => {
    setIsLoading(true);
    await NetworkService.Vendors.getVendor(vendorDocID)
      .then(v => {
        Logger({ objs: { v } }, fetchVendor);
        setVendor(v);
        setIsLoading(false);
      })
      .catch(e => {
        Logger({ objs: { e }, type: DemmiLogType.error }, fetchVendor);
        setVendor(undefined);
        setIsLoading(false);
      });
  };

  return (
    <div className={`${CSSBlock}__vendor-details`}>
      {vendor && (
        <>
          <div className={`${CSSBlock}__key-val`}>
            <div className={`${CSSBlock}__key`}>Name</div>
            <div className={`${CSSBlock}__value`}>{vendor.name}</div>
          </div>
          <div className={`${CSSBlock}__key-val`}>
            <div className={`${CSSBlock}__key`}>Address</div>
            <div className={`${CSSBlock}__value`}>{getVendorAddress(vendor)}</div>
          </div>
          <div className={`${CSSBlock}__key-val`}>
            <div className={`${CSSBlock}__key`}>Contact Number</div>
            <div className={`${CSSBlock}__value`}>{formatPhoneNumber(vendor.contactNumber)}</div>
          </div>
          <div className={`${CSSBlock}__divider`} />
          <OrderItemTabVendorDetailsHours CSSBlock={CSSBlock} hours={vendor.shop.openingHours} />
        </>
      )}
      <ElementLoadingIndicator isLoading={isLoading} />
    </div>
  );
};

export default OrderItemTabVendorDetails;
