import React, { useState } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, defer } from 'react-router-dom';

import ComingSoon from '@components/comingSoon/ComingSoon';
import LoadingIntro from '@components/loadingIntro/LoadingIntro';
import NotFound404 from '@pages/404/NotFound404';
import InviteCodes from '@pages/inviteCodes/InviteCodes';
import Login from '@pages/login/Login';
import OrderHistory from '@pages/orderHistory/OrderHistory';
import Welcome from '@pages/welcome/Welcome';
import YourAccount from '@pages/yourAccount/YourAccount';
import { Logger } from '@subhanhabib/demmilib';

import { auth } from '../firebase';
import Home from '../pages/home/Home';
import AuthLayout from './AuthLayout';
import AccountSetupRoute from './routeGuards/AccountSetupRoute';
import ProtectedRoute from './routeGuards/ProtectedRoute';
import UnprotectedRoute from './routeGuards/UnprotectedRoute';
import { RoutingViews, routingPaths } from './services/routingService';

const AppCore: React.FC = () => {
  const [isLoadingIntro, setIsLoadingIntro] = useState(true);
  const [_, setIsLoadingContent] = useState(false);

  // Necessary because auth is always null before initialising,
  // and Firebase doesn't return a promise for initialisation.
  const getUser = () => {
    Logger({ messages: 'getUser', collapsed: false }, AppCore);
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        Logger({ objs: { user }, collapsed: false }, AppCore);
        unsubscribe();
        resolve(user);
      }, reject);
    });
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AuthLayout />} loader={() => defer({ user: getUser() })} errorElement={<NotFound404 />}>
        <Route element={<UnprotectedRoute />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route element={<AccountSetupRoute />}>
          <Route path={RoutingViews.USER_SETUP} element={<Welcome />} />
        </Route>

        <Route path="/" element={<ProtectedRoute />}>
          <Route path={RoutingViews.HOME} element={<Home />} />

          <Route path={routingPaths[RoutingViews.INVITE_CODES]} element={<InviteCodes />}>
            <Route path={routingPaths[RoutingViews.INVITE_CODES_DETAIL]} element={<InviteCodes />} />
          </Route>

          <Route path={RoutingViews.ORDER_HISTORY} element={<OrderHistory />} />
          <Route path={RoutingViews.YOUR_ACCOUNT} element={<YourAccount />} />
          <Route path={RoutingViews.SETTINGS} element={<ComingSoon />} />
        </Route>
      </Route>,
    ),
  );

  return (
    <>
      <RouterProvider router={router} />

      {isLoadingIntro && (
        <LoadingIntro onAnimStart={() => setIsLoadingContent(true)} onAnimEnd={() => setIsLoadingIntro(false)} />
      )}
    </>
  );
};

export default AppCore;
