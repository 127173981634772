import React from 'react';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  orderVendor: DemmiFS.FSOrderVendor;
}

const OrderItemTabVendorDetails: React.FC<Props> = ({ CSSBlock, orderVendor }) => {
  return (
    <div className={`${CSSBlock}__vendor-details`}>
      <div className={`${CSSBlock}__key-val`}>
        <div className={`${CSSBlock}__key`}>Name</div>
        <div className={`${CSSBlock}__value`}>{orderVendor.name}</div>
      </div>
      {/* <div className={`${CSSBlock}__key-val`}>
        <div className={`${CSSBlock}__key`}>Customer Contact Number</div>
        <div className={`${CSSBlock}__value`}>{orderVendor.}</div>
      </div> */}
    </div>
  );
};

export default OrderItemTabVendorDetails;
