import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DemmiFS } from '@subhanhabib/demmilib';
import OrderItemVendorContent from './vendorContent/OrderItemVendorContent';
import { SearchParams } from '@core/services/routingService';
import OrderItemInfo from './partials/_order-info';
import OrderItemVendorButton from './vendorContent/partials/_vendor-button';
import { NetworkService } from '@core/services/networkService/networkService';

export enum OrdersPageTabs {
  TIMELINE = 'Timeline',
  PRODUCTS = 'Products',
  CUSTOMER_DETAILS = 'Customer Details',
  REVIEW = 'Review',
  REFUND_STATUS = 'Refund Status',
}

interface Props {
  orderDocID: string;
}

const OrderItem: React.FC<Props> = ({ orderDocID }) => {
  const CSSBlock = 'order-item';

  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState<DemmiFS.Order>();
  const [activeVendorDocID, setActiveVendorDocID] = useState<string>();
  const [activeVendor, setActiveVendor] = useState<DemmiFS.FSOrderVendor>();

  const orderCallback = () => (order?: DemmiFS.Order) => {
    setOrder(order);
    setActiveVendorDocID(undefined);
    setActiveVendor(undefined);
  };

  useEffect(() => {
    const snapshotListeners: Unsubscribe[] = [];
    NetworkService.Orders.listenToOrder(orderDocID, orderCallback()).then(u => snapshotListeners.push(u));
    return () => snapshotListeners.forEach(snapshot => snapshot());
  }, [orderDocID]);

  useEffect(() => {
    if (order && searchParams.get(SearchParams.ACTIVE_VENDOR)) {
      const [docID, vendor] =
        Object.entries(order.vendors).find(([docID, _]) => docID === searchParams.get(SearchParams.ACTIVE_VENDOR)) ??
        [];

      if (docID && vendor) {
        setActiveVendorDocID(docID);
        setActiveVendor(vendor);
      }
    } else {
      // setActiveTab(0);
    }
  }, [searchParams, order]);

  const onClickVendor = (vendorDocID: string, vendor: DemmiFS.FSOrderVendor) => {
    searchParams.set(SearchParams.ACTIVE_VENDOR, vendorDocID);
    setSearchParams(searchParams);
    setActiveVendorDocID(vendorDocID);
    setActiveVendor(vendor);
  };

  const getOrderTitle = (order: DemmiFS.Order) => {
    const productsCount = Object.values(order.vendors).reduce((acc, cur) => acc + cur.products.length, 0);
    const vendorCount = Object.keys(order.vendors).length;
    const totalCost = `${DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(order.cost.currencyCode)}${order.cost.net}`;
    let str = `Your order of `;
    str += productsCount === 1 ? `a single item, ` : `${productsCount} items, `;
    str += vendorCount === 1 ? `from a single place, ` : `from ${vendorCount} places, `;
    str += `totalling ${totalCost}`;
    return str;
  };

  return (
    <div className={`${CSSBlock}`}>
      {order && (
        <>
          <div className={`${CSSBlock}__section-title`}>Order Breakdown</div>
          <div className={`${CSSBlock}__description`}>{getOrderTitle(order)}</div>
          <OrderItemInfo order={order} CSSBlock={CSSBlock} />

          <div className={`${CSSBlock}__divider`} />

          <div className={`${CSSBlock}__section-title`}>Select a shop</div>
          <div className={`${CSSBlock}__vendors`}>
            {Object.entries(order.vendors).map(([vendorDocID, vendor]) => (
              <OrderItemVendorButton
                CSSBlock={CSSBlock}
                active={false}
                vendor={vendor}
                vendorDocID={vendorDocID}
                onClickVendor={() => onClickVendor(vendorDocID, vendor)}
                key={vendorDocID}
              />
            ))}
            {Object.entries(order.vendors).map(([vendorDocID, vendor]) => (
              <OrderItemVendorButton
                CSSBlock={CSSBlock}
                active={true}
                vendor={vendor}
                vendorDocID={vendorDocID}
                onClickVendor={() => onClickVendor(vendorDocID, vendor)}
                key={vendorDocID}
              />
            ))}
            {Object.entries(order.vendors).map(([vendorDocID, vendor]) => (
              <OrderItemVendorButton
                CSSBlock={CSSBlock}
                active={false}
                vendor={vendor}
                vendorDocID={vendorDocID}
                onClickVendor={() => onClickVendor(vendorDocID, vendor)}
                key={vendorDocID}
              />
            ))}
          </div>

          {activeVendor && activeVendorDocID && (
            <>
              <div className={`${CSSBlock}__divider`} />
              <div className={`${CSSBlock}__section-title`}>{activeVendor.name} Details</div>
              <div className={`${CSSBlock}__vendor-content`}>
                <OrderItemVendorContent
                  orderDocID={order.docID}
                  vendorDocID={activeVendorDocID}
                  vendor={activeVendor}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OrderItem;
