import { Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import DemmiTextArea from '@demmi-ui/TextArea';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';
import DemmiButton from '@demmi-ui/Buttons/Button';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  CSSBlock: string;
  vendorDocID: string;
  order: DemmiFS.Order;
}

const OrderItemTabTimelineUpdate: React.FC<Props> = ({ CSSBlock, vendorDocID, order }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [text, setText] = useState('');

  const onClickUpdate = () => {
    setIsUpdating(true);
    NetworkService.Orders.updateOrderTimeline(order.docID, {
      text,
      timestamp: Timestamp.now(),
      vendorID: vendorDocID,
      updatedBy: DemmiFS.OrderTimelineHelper.UpdatedBy.CUSTOMER,
    })
      .then(() => {
        setIsUpdating(false);
        setText('');
      })
      .catch(err => {
        setIsUpdating(false);
        Logger({ objs: { err }, type: DemmiLogType.error }, onClickUpdate);
      });
  };

  return (
    <div className={`${CSSBlock}__update ${isUpdating ? `${CSSBlock}__update--is-loading` : ``}`}>
      <div className={`${CSSBlock}__update-title`}>Update status</div>
      <DemmiTextArea placeholder="Enter an update" value={text} onChange={t => setText(t)} />
      <div className={`${CSSBlock}__buttons`}>
        <DemmiButton text="Send Update" faIcon="fa-paper-plane-top" onClick={onClickUpdate} />
      </div>
      <ElementLoadingIndicator isLoading={isUpdating} />
    </div>
  );
};

export default OrderItemTabTimelineUpdate;
