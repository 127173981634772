import {
  DocumentReference,
  QueryDocumentSnapshot,
  Unsubscribe,
  addDoc,
  arrayUnion,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { parseToOrder } from './_helper';
import { getOrdersQuery, orderQuery, orderReviewsVendorQuery } from './_queries';
import { FSSubCollectionNames } from '../networkService.helper';

export class FSOrders {
  static _getOrders = async (customerID: string): Promise<DemmiFS.Order[]> => {
    const querySnapshot = await getDocs(getOrdersQuery(customerID));
    const orders: DemmiFS.Order[] = [];

    querySnapshot.forEach(async (doc: QueryDocumentSnapshot<DemmiFS.FSOrder>) => {
      orders.push(parseToOrder(doc));
    });

    Logger({ objs: { orders } }, this._getOrders);
    return orders;
  };

  static _listenToOrder = async (
    orderDocID: string,
    callback: (order?: DemmiFS.Order) => void,
  ): Promise<Unsubscribe> => {
    return onSnapshot(orderQuery(orderDocID), async querySnapshot => {
      if (querySnapshot.exists()) {
        callback(parseToOrder(querySnapshot));
      } else {
        Logger({ messages: ['No such document'] }, this._listenToOrder);
        callback(undefined);
      }
    });
  };

  static _listenToOrderReview = async (
    orderID: string,
    vendorID: string,
    callback: (review?: DemmiFS.VendorReview) => void,
  ): Promise<Unsubscribe | undefined> => {
    const docSnap = await getDoc(doc(FSCollections.Orders, orderID));
    if (!docSnap.exists()) {
      return;
    }
    return onSnapshot(orderReviewsVendorQuery(vendorID, docSnap.data().orderID), async querySnapshot => {
      if (querySnapshot.empty) callback(undefined);
      else {
        const review = querySnapshot.docs[0].data();
        // review.id = querySnapshot.docs[0].id;
        callback({ docID: querySnapshot.docs[0].id, vendorID: vendorID, ...review });
      }
    });
  };

  static _updateOrderReview = async (
    vendorID: string,
    review: DemmiFS.FSVendorReview,
    reviewID?: string,
  ): Promise<void | DocumentReference<DemmiFS.FSVendorReview>> => {
    if (reviewID) {
      const docRef = doc(FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]), reviewID);
      return updateDoc(docRef, {
        body: review.body,
        title: review.title,
        timestamp: review.timestamp,
        // updatedAt:
      });
    } else {
      return addDoc(FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]), review);
    }
  };

  static _updateOrderTimeline = async (orderID: string, update: DemmiFS.OrderTimelineUpdate): Promise<void> => {
    const order = await getDoc(orderQuery(orderID));
    if (!order) {
      Logger(
        {
          messages: ['Failed to find order to push update.'],
          objs: { orderID, update },
          type: DemmiLogType.error,
        },
        this._updateOrderTimeline,
      );
      return;
    }

    const docRef = doc(FSCollections.Orders, orderID);
    return updateDoc(docRef, {
      timeline: arrayUnion(update),
    });
  };
}
