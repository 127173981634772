import { DemmiFS, DemmiHelpers, Logger } from '@subhanhabib/demmilib';
import { FSCollectionNames } from '../networkService.helper';
import { doc, DocumentData, getDoc, getDocs, QueryDocumentSnapshot } from 'firebase/firestore';
import { FSCollections } from '@providers/firestoreProvider';
import { db } from '../../../../firebase';

export class FSVendors {
  static _getVendor = async (vendorID: string): Promise<DemmiFS.VendorWithRaw | undefined> => {
    const snap = await getDoc(doc(db, FSCollectionNames.VENDORS, vendorID));

    if (snap.exists()) {
      const newVendor: DemmiFS.Vendor = snap.data() as DemmiFS.Vendor;
      if (!newVendor.shop?.openingHours) {
        newVendor.shop = {
          openingHours: DemmiHelpers.Vendor.EmptyOpeningHours,
        };
      }
      return {
        ...newVendor,
        raw: snap.data(),
        docID: snap.id,
      };
    } else {
      Logger({ messages: 'No such document!' }, this._getVendor);
    }
    return undefined;
  };

  static getVendors = async (): Promise<DemmiFS.VendorWithRaw[]> => {
    const querySnapshot = await getDocs(FSCollections.Vendors);
    const vendors: DemmiFS.VendorWithRaw[] = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      vendors.push({
        docID: doc.id,
        raw: doc.data(),
        ...(doc.data() as DemmiFS.FSVendor),
      });
    });
    return vendors;
  };
}
