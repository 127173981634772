import React, { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@core/hooks/appHooks';
import { navigateTransitioned } from '@helpers/app.helper';
import PortalSidebar from './_sidebar';
import PortalNavBar from './_navbar';
import PortalContent from './_content';

interface Props extends PropsWithChildren {
  isMounted: boolean;
  shouldRender: boolean;
}

const Portal: React.FC<Props> = ({ isMounted, shouldRender, children }) => {
  const CSSBlock = 'portal';
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div className={`${CSSBlock} ${isMenuVisible ? `${CSSBlock}--menu-visible` : ''}`}>
      <PortalSidebar
        navigateTransitioned={to => {
          navigateTransitioned({ dispatch, navigate, to });
          setIsMenuVisible(false);
        }}
      />
      <PortalContent
        CSSBlock={`${CSSBlock}`}
        isMounted={isMounted}
        shouldRender={shouldRender}
        setIsMenuVisible={setIsMenuVisible}>
        {children}
      </PortalContent>
      <PortalNavBar CSSBlock={`${CSSBlock}`} setIsMenuVisible={setIsMenuVisible} />
      <div id="modal-container" />
    </div>
  );
};

export default Portal;
