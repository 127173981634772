import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '@core/hooks/appHooks';
import { listenToOrder, listenToOrderReview } from '@core/services/networkService/orders/orders';

import { SearchParams } from '@core/services/routingService';

import DemmiCard from '@demmi-ui/Card/Card';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrderItemCollectionInfo from './partials/_collection-info';

import OrderItemVendorCosting from './partials/_costing';
import ComingSoon from '@components/comingSoon/ComingSoon';
import OrderItemTabProducts from './tabs/_tab-products/_tab-products';
import OrderItemTabTimeline from './tabs/_tab-timeline/_tab-timeline';
import OrderItemTabVendorDetails from './tabs/_tab-vendor-details';

export enum OrdersPageTabs {
  TIMELINE = 'Timeline',
  PRODUCTS = 'Products',
  VENDOR_DETAILS = 'Vendor Details',
  REVIEW = 'Review',
  REFUND_STATUS = 'Refund Status',
}

interface Props {
  orderDocID: string;
  vendorDocID: string;
  vendor: DemmiFS.FSOrderVendor;
}

const OrderItemVendor: React.FC<Props> = ({ orderDocID, vendorDocID, vendor }) => {
  const CSSBlock = 'order-item-vendor';

  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState<DemmiFS.Order>();
  const [orderVendor, setOrderVendor] = useState<DemmiFS.FSOrderVendor>();
  const [orderReview, setOrderReview] = useState<DemmiFS.VendorReview>();
  const [refundRequestID, setRefundRequestID] = useState<string>();
  const [status, setStatus] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useAppDispatch();

  const tabTitles = ['Timeline', 'Products', 'Vendor Details', 'Review', 'Refund Status'];
  const tabContents = [
    <OrderItemTabTimeline CSSBlock={CSSBlock} order={order!} vendorDocID={vendorDocID} key={0} />,
    <OrderItemTabProducts CSSBlock={CSSBlock} orderVendor={orderVendor!} vendorDocID={vendorDocID} key={1} />,
    <OrderItemTabVendorDetails CSSBlock={CSSBlock} orderVendor={orderVendor!} key={2} />,
    <div style={{ height: '30rem', position: 'relative' }} key={3}>
      <ComingSoon />
    </div>,
    //<OrderItemTabReview CSSBlock={CSSBlock} review={orderReview} vendorID={vendorDocID} key={3} />,
    <div style={{ height: '30rem', position: 'relative' }} key={4}>
      <ComingSoon />
    </div>,
    // <OrderItemTabRefund order={order!} refundRequestID={refundRequestID} key={4} />,
  ];

  const orderCallback = () => (order?: DemmiFS.Order) => {
    setOrder(order);
    if (order && order.vendors[vendorDocID]) setOrderVendor(order.vendors[vendorDocID]);
    else setOrderVendor(undefined);
    // if (order) setStatus(DemmiFS.OrderStatusHelper.getStatusFromOrder(vendorDocID, order));
  };
  const reviewCallback = (r?: DemmiFS.VendorReview) => setOrderReview(r);

  useEffect(() => {
    const snapshotListeners: Unsubscribe[] = [];
    if (vendor) {
      listenToOrder(orderDocID, orderCallback()).then(u => snapshotListeners.push(u));
      // listenToOrderReview(orderDocID, vendorDocID, reviewCallback).then(u => {
      //   if (u) snapshotListeners.push(u);
      // });
    }
    return () => snapshotListeners.forEach(snapshot => snapshot());
  }, [vendor, orderDocID]);

  useEffect(() => {
    if (searchParams.get(SearchParams.ACTIVE_TAB)) {
      const tabIndex = Object.values(OrdersPageTabs).findIndex(
        tab => tab === searchParams.get(SearchParams.ACTIVE_TAB),
      );
      if (tabIndex > -1) setActiveTab(tabIndex);
    } else {
      setActiveTab(0);
    }
  }, [searchParams]);

  const onClickTab = (i: number) => {
    searchParams.set(SearchParams.ACTIVE_TAB, Object.values(OrdersPageTabs)[i]);
    setSearchParams(searchParams);
    setActiveTab(i);
  };

  return (
    <div className={`${CSSBlock}__wrapper`}>
      {order && orderVendor && (
        <>
          <div className={CSSBlock}>
            {/* <div className={`${CSSBlock}__name`}>{orderVendor.name}</div> */}
            <OrderItemCollectionInfo order={order} />
            <OrderItemVendorCosting CSSBlock={CSSBlock} orderVendor={orderVendor} />
            <DemmiCard
              activeTab={activeTab}
              tabs={tabTitles}
              onClickTab={onClickTab}
              tabContents={tabContents}
              className={`${CSSBlock}__details`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OrderItemVendor;
