import { QueryDocumentSnapshot, Timestamp, addDoc, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiHelpers, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { functions } from '../../../../firebase';
import { getInviteCodesQuery, isInviteCodeEmailAvailableQuery } from '../inviteCodes/_queries';
import { CloudFunctions } from '../networkService.helper';
import { parseToInviteCode } from './_helper';

export const getInviteCodes = async (customerDocID: string): Promise<DemmiFS.InviteCode[]> => {
  const querySnapshot = await getDocs(getInviteCodesQuery(customerDocID));
  const codes: DemmiFS.InviteCode[] = [];

  querySnapshot.forEach(async (doc: QueryDocumentSnapshot<DemmiFS.FSInviteCode>) => {
    codes.push(parseToInviteCode(doc));
  });

  Logger({ objs: { codes } }, getInviteCodes);
  return codes;
};

export const isEmailAvailable = async (email: string): Promise<boolean> => {
  const querySnapshot = await getDocs(isInviteCodeEmailAvailableQuery(email));
  return querySnapshot.empty;
};

export const createInviteCode = async (
  code: Omit<DemmiFS.FSInviteCode, 'createdAt' | 'inviteSentAt'>,
): Promise<string | boolean> => {
  const currentCodes = await getInviteCodes(code.sourceCustomerID);
  const remainingCodes = DemmiHelpers.InviteCodes.countRemaining(currentCodes);
  if (remainingCodes > 0) {
    const docRef = await addDoc(FSCollections.InviteCodes, {
      ...code,
      createdAt: Timestamp.fromDate(new Date()),
    });
    Logger({ messages: ['New Code'], objs: { id: docRef.id } }, createInviteCode);
    return docRef.id;
  } else {
    Logger({ messages: ['Exceeded invite code count.'], type: DemmiLogType.error }, createInviteCode);
    return false;
  }
};

export const resendInviteCodeEmail = async (inviteCodeDocID: string): Promise<void> => {
  const sendEmail = httpsCallable(functions, CloudFunctions.SEND_INVITE_CODE_EMAIL);
  return sendEmail({ inviteCodeID: inviteCodeDocID }).then(result => {
    Logger({ objs: { data: result.data } }, resendInviteCodeEmail);
  });
};

export const revokeInviteCode = async (inviteCodeDocID: string): Promise<void> => {
  const revoke = httpsCallable(functions, CloudFunctions.REVOKE_INVITE_CODE);
  return revoke({ inviteCodeID: inviteCodeDocID }).then(result => {
    Logger({ objs: { data: result.data } }, revokeInviteCode);
  });
};
