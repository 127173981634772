import React, { createContext, useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutingViews, routingPaths } from '@core/services/routingService';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS, DemmiHelpers, DemmiHooks, DemmiLogType, Logger } from '@subhanhabib/demmilib';
import {
  selectFirestoreUser,
  selectIsFetchingFirestoreUser,
  setFirestoreUser,
  setIsFetchingFirestoreUser,
} from '@core/store/userSlice/userSlice';
import { useAuth } from '@providers/authProvider';
import { FSCustomer } from '@core/services/networkService/customer/customer';
import LoadingGlobal from '@components/loadingIntro/LoadingGlobal';
import { selectIsLoading } from '@core/store/appSlice/appSlice';

export const FirestoreUserContext = createContext<DemmiFS.User | undefined>(undefined);

export const FirestoreUserProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const firestoreUser = useAppSelector(selectFirestoreUser);
  const isFetchingFirestoreUser = useAppSelector(selectIsFetchingFirestoreUser);
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const isLoading = useAppSelector(selectIsLoading);
  const [isPageLoadingMounted, setIsPageLoadingMounted] = useState(false);
  const shouldRenderPageLoading = DemmiHooks.useDelayUnmount(isPageLoadingMounted, 400);
  useEffect(() => {
    Logger({ objs: { isFetchingFirestoreUser }, collapsed: false }, FirestoreUserProvider);
    setIsPageLoadingMounted(isFetchingFirestoreUser || isLoading);
  }, [isFetchingFirestoreUser, isLoading]);

  const getFirestoreUser = () => {
    Logger({ messages: 'getFirestoreUser', collapsed: false }, FirestoreUserProvider);
    if (user && user.providerData.length > 0) {
      const provider = DemmiFS.AuthProviderHelper.getFBAuthProviderByID(user.providerData[0].providerId);
      if (provider) {
        demmiRequest(
          (provider === DemmiFS.AuthProviderHelper.FBAuthProvider.apple
            ? FSCustomer.doc.getByProviderApple
            : FSCustomer.doc.getByProviderGoogle)(user.uid),
        ).then(customer => {
          dispatch(setFirestoreUser(customer));
          dispatch(setIsFetchingFirestoreUser(false));
        });
      } else {
        Logger(
          { messages: ['Failed to parse provider'], objs: { provider: provider }, type: DemmiLogType.error },
          FirestoreUserProvider,
        );
        dispatch(setIsFetchingFirestoreUser(false));
      }
    } else {
      dispatch(setFirestoreUser(undefined));
      dispatch(setIsFetchingFirestoreUser(false));
    }
  };

  useEffect(() => {
    if (!firestoreUser) getFirestoreUser();
  }, [firestoreUser, user]);

  return (
    <FirestoreUserContext.Provider value={firestoreUser}>
      {firestoreUser && !DemmiHelpers.Customer.isSetupComplete(firestoreUser) ? (
        <Navigate to={routingPaths[RoutingViews.USER_SETUP]} />
      ) : (
        children
      )}
      {shouldRenderPageLoading && <LoadingGlobal isMounted={isPageLoadingMounted} />}
    </FirestoreUserContext.Provider>
  );
};

export const useFirestoreUser = (): DemmiFS.User | undefined => {
  const context = useContext(FirestoreUserContext);
  if (context === undefined) throw new Error('useFirestoreUser must be used within a FirestoreUserProvider');
  return context;
};
