import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrderItemCoupon from '../../partials/_coupon';

interface Props {
  orderVendor: DemmiFS.FSOrderVendor;
  CSSBlock: string;
}

const OrderItemVendorCosting: React.FC<Props> = ({ CSSBlock, orderVendor }) => {
  return (
    <DemmiCard
      title={`Cost breakdown`}
      rootClassName={`${CSSBlock}__costing`}
      className={`${CSSBlock}__costing-contents`}>
      {orderVendor.coupon?.type && (
        <OrderItemCoupon
          CSSBlock={CSSBlock}
          currencySymbol={DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(orderVendor.cost.currencyCode)}
          coupon={orderVendor.coupon}
          grossCost={orderVendor.cost.gross}
        />
      )}
      <div className={`${CSSBlock}__key-val`}>
        <div className={`${CSSBlock}__key`}>Cost (Gross)</div>
        <div className={`${CSSBlock}__value`}>
          {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(orderVendor.cost.currencyCode)}
          {Number(orderVendor.cost.gross).toFixed(2)}
        </div>
      </div>
      <div className={`${CSSBlock}__key-val`}>
        <div className={`${CSSBlock}__key`}>Cost (Net)</div>
        <div className={`${CSSBlock}__value`}>
          {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(orderVendor.cost.currencyCode)}
          {Number(orderVendor.cost.net).toFixed(2)}
        </div>
      </div>
      <div className={`${CSSBlock}__key-val`}>
        <div className={`${CSSBlock}__key`}>Total Order Cost</div>
        <div className={`${CSSBlock}__value`}>
          {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(orderVendor.cost.currencyCode)}
          {Number(DemmiFS.OrderHelper.getTotalOrderCost(orderVendor)).toFixed(2)}
        </div>
      </div>
    </DemmiCard>
  );
};

export default OrderItemVendorCosting;
