import React from 'react';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  hours: DemmiFS.VendorOpeningHours;
}

const OrderItemTabVendorDetailsHours: React.FC<Props> = ({ CSSBlock, hours }) => {
  const Day = <K extends keyof DemmiFS.VendorOpeningHours>(title: string, day: K) => (
    <div className={`${CSSBlock}__day-item`}>
      <div className={`${CSSBlock}__day-title`}>{title}</div>
      <div className={`${CSSBlock}__day-hours`}>
        <div className={`${CSSBlock}__day-hours-item`}>
          <div className={`${CSSBlock}__day-hours-item-title`}>Open</div>
          <div className={`${CSSBlock}__day-hours-item-value`}>
            {DemmiHelpers.DemmiTime.timeToString(hours[day]!.open)}
          </div>
        </div>
        <div className={`${CSSBlock}__day-hours-item`}>
          <div className={`${CSSBlock}__day-hours-item-title`}>Close</div>
          <div className={`${CSSBlock}__day-hours-item-value`}>
            {DemmiHelpers.DemmiTime.timeToString(hours[day]!.close)}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`${CSSBlock}__opening-hours`}>
      <div className={`${CSSBlock}__opening-hours-title`}>Opening Hours</div>
      {Day('Monday', 'mon')}
      {Day('Tuesday', 'tue')}
      {Day('Wednesday', 'wed')}
      {Day('Thursday', 'thu')}
      {Day('Friday', 'fri')}
      {Day('Saturday', 'sat')}
      {Day('Sunday', 'sun')}
    </div>
  );
};

export default OrderItemTabVendorDetailsHours;
