import { getDownloadURL, ref } from 'firebase/storage';

import { storage } from '../../../firebase';

const STORAGE_PATH_PRODUCT_IMAGES = '/product_images';
const STORAGE_PATH_VENDOR_IMAGES = '/vendor_images';

export const getProductImage = (vendorDocID: string, src: string): Promise<string> => {
  return getImage(`/${vendorDocID}${STORAGE_PATH_PRODUCT_IMAGES}/${src}`);
};

export const getVendorImage = (vendorDocID: string, src: string): Promise<string> => {
  return getImage(`/${vendorDocID}${STORAGE_PATH_VENDOR_IMAGES}/${src}`);
};

export const getImage = (src: string): Promise<string> => {
  const pathReference = ref(storage, src);

  return new Promise((resolve, reject) => {
    getDownloadURL(pathReference)
      .then(url => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = _ => {};
        xhr.open('GET', url);
        resolve(url);
      })
      .catch(error => {
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break;
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
          default:
            break;
        }
        reject();
      });
  });
};
