import { UserCredential } from 'firebase/auth';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DemmiFS } from '@subhanhabib/demmilib';

import { RootState } from '../store';

interface UserState {
  cred: UserCredential | undefined;
  firestoreUser: DemmiFS.User | undefined;
  isFetchingFirestoreUser: boolean;
}

const initialState: UserState = {
  cred: undefined,
  firestoreUser: undefined,
  isFetchingFirestoreUser: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: builder => {
    builder.addCase('LOGOUT', state => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    clearCred: _ => {},
    storeCred: (_1, _2: PayloadAction<UserCredential>) => {},
    setFirestoreUser: (state, action: PayloadAction<DemmiFS.User | undefined>) => {
      state.firestoreUser = action.payload;
    },
    setIsFetchingFirestoreUser: (state, action: PayloadAction<boolean>) => {
      state.isFetchingFirestoreUser = action.payload;
    },
  },
});

export const { clearCred, storeCred, setFirestoreUser, setIsFetchingFirestoreUser } = userSlice.actions;

export const selectUserCred = (state: RootState) => state.user.cred;
export const selectFirestoreUser = (state: RootState) => state.user.firestoreUser;
export const selectIsFetchingFirestoreUser = (state: RootState) => state.user.isFetchingFirestoreUser;

export default userSlice.reducer;
