import { UserCredential } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingGlobal from '@components/loadingIntro/LoadingGlobal';
import { useAppDispatch } from '@core/hooks/appHooks';

import { FSCustomer } from '@core/services/networkService/customer/customer';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { setFirestoreUser } from '@core/store/userSlice/userSlice';
import { useAuth } from '@providers/authProvider';
import { DemmiFS, DemmiHooks, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import CardAuthentication from './_partials/CardAuthentication';
import CardUserNotFound from './_partials/CardUserNotFound';

const Login: React.FC = () => {
  const CSSBlock = 'login';
  const [isVisible, setIsVisible] = useState(false);
  const { signInGoogle, signInApple } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.body.classList.add('theme-dark');
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  /**
   * -1 = CardUserNotFound
   * 0 = CardAuthentication
   */
  const [activeCard, setActiveCard] = useState(0);

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [isGlobalLoadingMounted, setIsGlobalLoadingMounted] = useState(false);
  const shouldRenderGlobalLoading = DemmiHooks.useDelayUnmount(isGlobalLoadingMounted, 200);
  useEffect(() => {
    setIsGlobalLoadingMounted(isLoading);
  }, [isLoading]);

  const onClickGoogle = async () => {
    setIsLoading(true);
    try {
      const userCredential = await signInGoogle();
      onPostCredential(userCredential);
    } catch (err) {
      Logger({ objs: { err }, stacktrace: true }, onClickGoogle);
    }
    setIsLoading(false);
  };

  const onClickApple = async () => {
    // setActiveCard(-1);
    setIsLoading(true);
    try {
      const userCredential = await signInApple();
      if (userCredential) onPostCredential(userCredential);
    } catch (err) {
      Logger({ objs: { err }, stacktrace: true }, onClickApple);
    }
    setIsLoading(false);
  };

  const onPostCredential = async (userCredential: UserCredential) => {
    Logger({ objs: { userCredential } }, onPostCredential);

    const provider = userCredential.providerId
      ? DemmiFS.AuthProviderHelper.getFBAuthProviderByID(userCredential.providerId)
      : undefined;
    if (!provider) {
      Logger({ type: DemmiLogType.error, messages: ['Failed to get auth provider'] }, onPostCredential);
      setActiveCard(-1);
      return;
    }

    Logger({ objs: { provider } }, onPostCredential);
    const docID = await FSCustomer.doc.getID(userCredential.user.uid, provider);
    Logger({ messages: 'exists?', objs: { docID } }, onPostCredential);
    if (!docID) {
      setActiveCard(-1);
    } else {
      const customer = await FSCustomer.doc.getByDocID(docID);
      dispatch(setFirestoreUser(customer));
      navigate(routingPaths[RoutingViews.HOME], { replace: true });
    }
  };

  const transitionRotation = 180;

  return (
    <>
      <div className={`${CSSBlock}__wrapper ${!isVisible ? `${CSSBlock}--hide` : ''} `}>
        <div className={`${CSSBlock}__content-position ${CSSBlock}__content-position--card-${activeCard}`}>
          <span
            className={`${CSSBlock}__content-card ${activeCard === -1 ? `${CSSBlock}__content-card--active` : ``}`}
            style={{
              transform: `rotate3d(0, 1, 0, ${(activeCard - 1) * -transitionRotation}deg)`,
            }}>
            <CardUserNotFound onClickBack={() => setActiveCard(0)} />
          </span>
          <span
            className={`${CSSBlock}__content-card ${activeCard === 0 ? `${CSSBlock}__content-card--active` : ``}`}
            style={{
              transform: `rotate3d(0, 1, 0, ${activeCard * -transitionRotation}deg)`,
            }}>
            <CardAuthentication onClickGoogle={onClickGoogle} onClickApple={onClickApple} />
          </span>
        </div>
      </div>

      {shouldRenderGlobalLoading && <LoadingGlobal isMounted={isGlobalLoadingMounted} isLogin />}
    </>
  );
};

export default Login;
