import React, { useEffect } from 'react';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';

import AppTheme from '@core/AppTheme';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { selectGlobalContentMounted, setGlobalContentMounted } from '@core/store/appSlice/appSlice';
import { useAuth } from '@providers/authProvider';
import { DemmiHooks, Logger } from '@subhanhabib/demmilib';
import { FirestoreUserProvider } from '@core/contexts/FirestoreUserContext';
import Portal from '@components/portal/Portal';

const ProtectedRoute: React.FC = () => {
  const isMounted = useAppSelector(selectGlobalContentMounted);
  const shouldRender = DemmiHooks.useDelayUnmount(isMounted, 100);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const outlet = useOutlet();

  useEffect(() => {
    dispatch(setGlobalContentMounted(true));
  }, [pathname]);

  return user ? (
    <FirestoreUserProvider>
      <AppTheme isLogin={false} />
      <Portal isMounted={isMounted} shouldRender={shouldRender}>
        {outlet}
      </Portal>
    </FirestoreUserProvider>
  ) : (
    (Logger({ messages: 'NO USER', objs: { user }, collapsed: false }, ProtectedRoute), (<Navigate to="/" />))
  );
};
export default ProtectedRoute;
