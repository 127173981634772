import React from 'react';

import DemmiCard from '@demmi-ui/CardOld/Card';
import DemmiInput from '@demmi-ui/Input';
import DemmiModalArea from '@demmi-ui/Modal/ModalArea';

interface Props {
  CSSBlock: string;
  state: number;
  code?: string;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const ModalCreateInviteCodeContent: React.FC<Props> = ({ state, CSSBlock, code, email, setEmail }) => {
  return (
    <DemmiModalArea isDark>
      <div className={`${CSSBlock}__title`}>Invite your human</div>
      <div className={`${CSSBlock}__content`}>
        <DemmiCard isLoading={state === 1 || state === 3}>
          <DemmiInput type="email" value={email} onChangeValue={val => setEmail(val)} />
          {state === -1 && <div className={`${CSSBlock}__error`}>Email address in use</div>}
          <div className={`${CSSBlock}__code-area`}>
            <div className={`${CSSBlock}__code-title`}>Generated code</div>
            <div className={`${CSSBlock}__code`}>{code ? code : '   -   '}</div>
          </div>
        </DemmiCard>
      </div>
    </DemmiModalArea>
  );
};

export default ModalCreateInviteCodeContent;
