import React from 'react';
import { DemmiFS } from '@subhanhabib/demmilib';
import AsyncImage from '@components/asyncImage/AsyncImage';

interface Props {
  CSSBlock: string;
  active: boolean;
  vendorDocID: string;
  vendor: DemmiFS.FSOrderVendor;
  onClickVendor: () => void;
}

const OrderItemVendorButton: React.FC<Props> = ({
  CSSBlock: parentCSSBlock,
  active,
  vendorDocID,
  vendor,
  onClickVendor,
}) => {
  const CSSBlock = `${parentCSSBlock}__vendor-button`;
  return (
    <div
      className={`${CSSBlock} ${active ? `${CSSBlock}--active` : ``}`}
      style={{ '--local-vendor-hue': vendor.themeHue }}
      key={vendorDocID}
      onClick={onClickVendor}>
      <div className={`${CSSBlock}-thumbnail`}>
        <AsyncImage vendorID={vendorDocID} isVendor src={vendor.image} />
      </div>
      <div className={`${CSSBlock}-content`}>
        <div className={`${CSSBlock}-name`}>{vendor.name}</div>
        <div className={`${CSSBlock}-details`}>
          <div className={`${CSSBlock}-products`}>{vendor.products.length} items</div>
          <div className={`${CSSBlock}-cost`}>
            {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(vendor.cost.currencyCode)}
            {Number(vendor.cost.gross).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemVendorButton;
