import React, { PropsWithChildren } from 'react';

import LogoWhite from '@assets/demmi-logo-white.svg';
import Logo from '@assets/demmi-logo.svg';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';

interface Props extends PropsWithChildren {
  CSSBlock: string;
  setIsMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PortalNavBar: React.FC<Props> = ({ CSSBlock, setIsMenuVisible }) => {
  return (
    <div className={`${CSSBlock}__nav-bar`}>
      <div className={`${CSSBlock}__menu-toggle-button`}>
        <DemmiIconButton invert className="fa-bars" faIcon="fa-bars" onClick={() => setIsMenuVisible(true)} />
        <DemmiIconButton
          invert
          className="fa-arrow-left"
          faIcon="fa-arrow-left"
          onClick={() => setIsMenuVisible(false)}
        />
      </div>
      <div className={`${CSSBlock}__nav-bar-logo`}>
        <img src={Logo} className={`${CSSBlock}__logo-colour`} />
        <img src={LogoWhite} className={`${CSSBlock}__logo-white`} />
      </div>
      <div className={`${CSSBlock}__nav-bar-empty`} />
    </div>
  );
};

export default PortalNavBar;
