import React from 'react';
import { DemmiFS } from '@subhanhabib/demmilib';
import AsyncImage from '@components/asyncImage/AsyncImage';

interface Props {
  CSSBlock: string;
  active: boolean;
  vendorDocID: string;
  vendor: DemmiFS.FSOrderVendor;
  onClickVendor: () => void;
}

const OrderItemVendorButton: React.FC<Props> = ({ CSSBlock, active, vendorDocID, vendor, onClickVendor }) => {
  return (
    <div
      className={`${CSSBlock}__vendor-button ${active ? `${CSSBlock}__vendor-button--active` : ``}`}
      style={{ '--local-vendor-hue': vendor.themeHue }}
      key={vendorDocID}
      onClick={onClickVendor}>
      <div className={`${CSSBlock}__vendor-thumbnail`}>
        <AsyncImage vendorID={vendorDocID} isVendor src={vendor.image} />
      </div>
      <div className={`${CSSBlock}__vendor-content`}>
        <div className={`${CSSBlock}__vendor-name`}>{vendor.name}</div>
        <div className={`${CSSBlock}__vendor-details`}>
          <div className={`${CSSBlock}__vendor-products`}>{vendor.products.length} items</div>
          <div className={`${CSSBlock}__vendor-cost`}>
            {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(vendor.cost.currencyCode)}
            {Number(vendor.cost.gross).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemVendorButton;
