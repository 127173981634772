import { Timestamp, Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import DemmiTextArea from '@demmi-ui/TextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCancel, faEdit, faSave } from '@fortawesome/pro-regular-svg-icons';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import DemmiButton from '@demmi-ui/Buttons/Button';
import { NetworkService } from '@core/services/networkService/networkService';
import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import DemmiInput from '@demmi-ui/Input';
import { selectFirestoreUser } from '@core/store/userSlice/userSlice';
import { useAppSelector } from '@core/hooks/appHooks';

interface Props {
  CSSBlock: string;
  orderDocID: string;
  vendorDocID: string;
}

const EMPTY_REVIEW = {
  body: [],
  title: '',
  customerID: '',
  orderID: '',
  rating: 0,
  timestamp: Timestamp.now(),
};

const OrderItemTabReview: React.FC<Props> = ({ CSSBlock, orderDocID, vendorDocID }) => {
  const customer = useAppSelector(selectFirestoreUser);
  const [review, setReview] = useState<DemmiFS.VendorReview>();
  const [editedReviewTitle, setEditedReviewTitle] = useState<string>('');
  const [editedReviewBody, setEditedReviewBody] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateReview = async () => {
    if (!editedReviewBody || !editedReviewTitle || !customer) return;
    console.log(editedReviewTitle);
    console.log(editedReviewBody);
    setIsLoading(true);
    const editedReview: DemmiFS.FSVendorReview = {
      ...EMPTY_REVIEW,
      customerID: customer.docID,
      title: editedReviewTitle,
      body: editedReviewBody.split('\n'),
      timestamp: Timestamp.now(),
    };
    await demmiRequest(NetworkService.Orders.updateOrderReview(vendorDocID, editedReview, review?.docID)).then(
      () => {},
    );
    setIsLoading(false);
  };

  useEffect(() => {
    const snapshotListeners: Unsubscribe[] = [];
    if (vendorDocID) {
      setIsLoading(true);
      NetworkService.Orders.listenToOrderReview(orderDocID, vendorDocID, reviewCallback).then(u => {
        if (u) snapshotListeners.push(u);
      });
    }
    return () => snapshotListeners.forEach(snapshot => snapshot());
  }, [vendorDocID, orderDocID]);

  const reviewCallback = (review?: DemmiFS.VendorReview) => {
    setReview(review);
    if (review) {
      setEditedReviewTitle(review.title);
      setEditedReviewBody(review.body.join('\n'));
    }
    setIsLoading(false);
  };

  return (
    <>
      {editMode ? (
        <div className={`${CSSBlock}__review ${CSSBlock}__review--edit-mode`}>
          <div className={`${CSSBlock}__review-response-title`}>Your review</div>
          <DemmiInput
            placeholder="Your review title"
            type="text"
            value={editedReviewTitle}
            onChangeValue={val => setEditedReviewTitle(val)}
          />
          <DemmiTextArea
            placeholder="Your review body"
            value={editedReviewBody}
            maxLength={1000}
            maxHeight={24}
            onChange={val => setEditedReviewBody(val)}
          />
          <div className={`${CSSBlock}__review-buttons`}>
            <DemmiIconButton
              icon={faCancel}
              onClick={() => {
                setEditedReviewTitle(review?.title ?? '');
                setEditedReviewBody(review?.body.join('\n') ?? 'EMPTY_REVIEW');
                setEditMode(false);
              }}
            />
            <DemmiIconButton
              icon={faSave}
              disabled={!editedReviewTitle || !editedReviewBody}
              onClick={() => {
                updateReview();
                setEditMode(false);
              }}
            />
          </div>
        </div>
      ) : review ? (
        <div className={`${CSSBlock}__review`}>
          <div className={`${CSSBlock}__review-timestamp`}>
            Reviewed on:
            <span>{DemmiFS.getDateFromFirestore(review.timestamp)?.toDateString()}</span>
          </div>
          <div className={`${CSSBlock}__review-title`}>{review.title}</div>
          <div className={`${CSSBlock}__review-description`}>
            {review.body.map((paragraph, i) => (
              <div className={`${CSSBlock}__review-description-paragraph`} key={i}>
                {paragraph}
              </div>
            ))}
          </div>
          <div className={`${CSSBlock}__review-rating`}>
            {[...Array(5)].map((_, i) => (
              <FontAwesomeIcon icon={review!.rating < i ? faStar : faStarSolid} key={i} />
            ))}
          </div>
          <div className={`${CSSBlock}__review-buttons`}>
            <DemmiButton text="Edit Your Review" faIcon="fa-edit" onClick={() => setEditMode(true)} />
          </div>

          <div className={`${CSSBlock}__review-response`}>
            {editMode ? (
              <></>
            ) : review.response ? (
              <>
                <div className={`${CSSBlock}__review-response-title`}>
                  Review response
                  <DemmiIconButton icon={faEdit} onClick={() => setEditMode(!editMode)} />
                </div>
                {review.response.body.map((paragraph, i) => (
                  <div className={`${CSSBlock}__review-description-paragraph`} key={i}>
                    {paragraph}
                  </div>
                ))}
              </>
            ) : (
              <div className={`${CSSBlock}__review-no-response`}>No response (yet...)</div>
            )}
          </div>
        </div>
      ) : !isLoading ? (
        <div className={`${CSSBlock}__review-none`}>
          You haven&apos;t yet reviewed this order
          <DemmiButton text="Review Now" faIcon="fa-edit" onClick={() => setEditMode(true)} />
        </div>
      ) : (
        <></>
      )}
      <ElementLoadingIndicator isLoading={isLoading} />
    </>
  );
};

export default OrderItemTabReview;
