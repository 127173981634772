import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrderItemCoupon from './_coupon';

interface Props {
  order: DemmiFS.Order;
  CSSBlock: string;
}

const OrderItemCosting: React.FC<Props> = ({ CSSBlock, order }) => {
  const currencySymbol = DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(order.cost.currencyCode);
  return (
    order && (
      <DemmiCard
        title={`Order Information`}
        rootClassName={`${CSSBlock}__costing`}
        className={`${CSSBlock}__costing-contents`}>
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Order #</div>
          <div className={`${CSSBlock}__value`}>{order.orderID}</div>
        </div>
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Order Date</div>
          <div className={`${CSSBlock}__value`}>{DemmiFS.getDateFromFirestore(order.orderDate)?.toLocaleString()}</div>
        </div>
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Gross Product Cost</div>
          <div className={`${CSSBlock}__value`}>
            {currencySymbol}
            {Number(order.cost.gross).toFixed(2)}
          </div>
        </div>
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Service Fee</div>
          <div className={`${CSSBlock}__value`}>
            {currencySymbol}
            {Number(order.cost.serviceFee).toFixed(2)}
          </div>
        </div>
        {order.coupon?.type && (
          <OrderItemCoupon
            CSSBlock={CSSBlock}
            currencySymbol={currencySymbol}
            coupon={order.coupon}
            grossCost={order.cost.gross}
          />
        )}
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Total Order Cost</div>
          <div className={`${CSSBlock}__value`}>
            {currencySymbol}
            {Number(order.cost.net).toFixed(2)}
          </div>
        </div>
      </DemmiCard>
    )
  );
};

export default OrderItemCosting;
