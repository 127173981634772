import { Timestamp } from 'firebase/firestore';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrderItemTabTimelineUpdate from './_tab-timeline-update';
import { faTimelineArrow } from '@fortawesome/pro-regular-svg-icons';
import DemmiTimeline, { DemmiTimelineItem } from '@demmi-ui/Timeline';

interface Props {
  CSSBlock: string;
  order: DemmiFS.Order;
  vendorDocID: string;
}

const OrderItemTabTimeline: React.FC<Props> = ({ CSSBlock, vendorDocID, order }) => {
  const timelineItems: DemmiTimelineItem[] = order.timeline.map(update => ({
    ...(update.statusUpdate
      ? {
          title: `Order ${DemmiFS.OrderStatusHelper.getOrderVendorStatusText(update.statusUpdate).toLowerCase()}`,
        }
      : {}),
    date: DemmiFS.getDateFromFirestore(
      typeof update.timestamp === 'number' ? Timestamp.fromMillis(update.timestamp) : update.timestamp,
    ),
    ...(update.text
      ? {
          text: update.text,
          textBadge: DemmiFS.OrderTimelineHelper.getUpdatedByText(update.updatedBy),
        }
      : {}),
  }));

  return (
    <div className={`${CSSBlock}__timeline`}>
      <div className={`${CSSBlock}__title`}>
        <FontAwesomeIcon size={'lg'} icon={faTimelineArrow} />
        Order Timeline
      </div>
      <DemmiTimeline items={timelineItems} />
      <OrderItemTabTimelineUpdate CSSBlock={CSSBlock} vendorDocID={vendorDocID} order={order} />
    </div>
  );
};

export default OrderItemTabTimeline;
