import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { getViewFromPath } from '@core/services/routingService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faSolid from '@fortawesome/pro-solid-svg-icons';

interface Props extends PropsWithChildren {
  CSSBlock: string;
  isMounted: boolean;
  shouldRender: boolean;
  setIsMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PortalContent: React.FC<Props> = ({ CSSBlock, isMounted, shouldRender, setIsMenuVisible, children }) => {
  const mountedStyle = { animation: 'inAnimation 400ms ease-in-out' };
  const unmountedStyle = { animation: 'outAnimation 200ms ease-in-out' };
  const { pathname } = useLocation();

  return (
    <div className={`${CSSBlock}__content-wrapper`} onClick={() => setIsMenuVisible(false)}>
      <div
        className={`${CSSBlock}__content ${CSSBlock}--${getViewFromPath(pathname)}`}
        style={isMounted ? mountedStyle : unmountedStyle}>
        {shouldRender && children}
      </div>

      <div className={`${CSSBlock}__foreground`} onClick={() => setIsMenuVisible(false)}>
        <span className={`${CSSBlock}__foreground-icon`}>
          <FontAwesomeIcon icon={faSolid.faLeft} />
        </span>
      </div>
    </div>
  );
};

export default PortalContent;
