import React from 'react';

import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  order: DemmiFS.Order;
  orderVendor: DemmiFS.FSOrderVendor;
}

const OrderItemCollectionInfo: React.FC<Props> = ({ order, orderVendor }) => {
  const CSSBlock = 'order-item-collection';

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Collection Code</div>
      <div className={`${CSSBlock}__code`}>
        {order.collectionCode.split('').map((e, i) => (
          <span key={i}>{e}</span>
        ))}
      </div>
      <div className={`${CSSBlock}__details`}>
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Collection Date</div>
          <div className={`${CSSBlock}__value`}>
            {orderVendor.collectionDate
              ? DemmiHelpers.Dates.formatCollectionDate(orderVendor.collectionDate)
              : 'No date given'}
          </div>
        </div>
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Collection Time</div>
          <div className={`${CSSBlock}__value`}>
            {orderVendor.collectionTime
              ? `${DemmiHelpers.DemmiTime.timeToString(
                  orderVendor.collectionTime,
                )} - ${DemmiHelpers.DemmiTime.timeToString(orderVendor.collectionTime + 30)}`
              : 'No time given'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemCollectionInfo;
