import { FSDemmi } from './demmi/demmi';
import { FSOrders } from './orders/orders';
import { FSVendors } from './vendors/vendors';

export class NetworkService {
  static Demmi = {
    populate: FSDemmi._populate,
  };
  static Orders = {
    getOrders: FSOrders._getOrders,
    listenToOrder: FSOrders._listenToOrder,
    listenToOrderReview: FSOrders._listenToOrderReview,
    updateOrderReview: FSOrders._updateOrderReview,
    updateOrderTimeline: FSOrders._updateOrderTimeline,
  };
  static Vendors = {
    getVendor: FSVendors._getVendor,
  };
}
