import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  currencySymbol: string;
  coupon: DemmiFS.FSOrderVendorCoupon;
  grossCost: number;
}

const OrderItemCoupon: React.FC<Props> = ({
  CSSBlock: parentBlock,
  currencySymbol,
  coupon,
  grossCost,
}) => {
  const CSSBlock = `${parentBlock}-coupon`;

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__wrapper-background`}></div>
      <div className={`${CSSBlock}__contents`}>
        <div className={`${CSSBlock}__code`}>{coupon.code}</div>
        <div className={`${CSSBlock}__content-details`}>
          <div className={`${CSSBlock}__type`}>{coupon.type}</div>
          <div className={`${CSSBlock}__detail-split`}>
            <div className={`${CSSBlock}__detail`}>
              <div className={`${CSSBlock}__key`}>Coupon Amount</div>
              <div className={`${CSSBlock}__value`}>
                {DemmiFS.CouponHelper.getVendorAmount(currencySymbol, coupon)}
              </div>
            </div>
            <div className={`${CSSBlock}__detail`}>
              <div className={`${CSSBlock}__key`}>Discount Applied</div>
              <div className={`${CSSBlock}__value`}>
                ${currencySymbol}
                {Number(coupon.amount * grossCost).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemCoupon;
